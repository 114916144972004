function IconFire() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="17"
      fill="none"
      viewBox="0 0 14 17"
    >
      <path
        fill="currentColor"
        d="M11.044 4.635c.038.737-.321 1.474-.831 2.249.415-2.514-.416-5.33-3.761-6.634.283 1.059-.397 2.363-1.058 3.78a9.335 9.335 0 00-1.399-1.304c.265 3.515-7.445 7.03-1.19 13.04 0 0 .415.264 1.152.529-.018-.02-.037-.038-.056-.057-1.342-1.455-2.079-4.46.472-6.444-.038.491.208.964.548 1.455-.264-1.625.265-3.458 2.438-4.309-.189.68.265 1.531.68 2.457.246-.302.548-.586.907-.85-.132 1.87 3.345 3.798 2.098 6.651a5.65 5.65 0 00.718-.642c2.06-2.23 3.194-6.86-.718-9.921z"
      ></path>
    </svg>
  );
}

export default IconFire;
